var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.$t("Inutilizar faixa documento fiscal"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.saveInvalidate, hidden: _vm.cancel },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      { ref: "form", attrs: { name: "form" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("e-store-combo", {
                                  attrs: {
                                    id: "storeId",
                                    name: "storeId",
                                    required: "",
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.invalidate.storeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invalidate, "storeId", $$v)
                                    },
                                    expression: "invalidate.storeId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "auto" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "model",
                                    type: "select",
                                    label: _vm.$t("Modelo"),
                                    options: _vm.getComboModelo,
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.invalidate.model,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invalidate, "model", $$v)
                                    },
                                    expression: "invalidate.model",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "auto" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "serialNumber",
                                    type: "number",
                                    label: _vm.$t("Número de Série"),
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.invalidate.serialNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invalidate,
                                        "serialNumber",
                                        $$v
                                      )
                                    },
                                    expression: "invalidate.serialNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "auto" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "initialNumber",
                                    type: "number",
                                    label: _vm.$t("Faixa Inicial"),
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.invalidate.initialNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invalidate,
                                        "initialNumber",
                                        $$v
                                      )
                                    },
                                    expression: "invalidate.initialNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "auto" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "finalNumber",
                                    type: "number",
                                    label: _vm.$t("Faixa Final"),
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.invalidate.finalNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invalidate,
                                        "finalNumber",
                                        $$v
                                      )
                                    },
                                    expression: "invalidate.finalNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "justification",
                                    type: "textarea",
                                    label: _vm.$t("Justificativa"),
                                    validation: "^required|min:15,length",
                                  },
                                  model: {
                                    value: _vm.invalidate.justification,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invalidate,
                                        "justification",
                                        $$v
                                      )
                                    },
                                    expression: "invalidate.justification",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }