<template>
  <b-tabs fill>
    <b-tab :title="$t('Sequências Inutilizadas')">
      <section>
        <e-filters
          :searching="fetching"
          @search="filterData"
          @reset="resetFiltersLocal"
        >
          <div>
            <b-row>
              <b-col md="2">
                <e-store-combo
                  v-model="filters.storeId"
                  :required="false"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="ncm"
                  v-model="filters.model"
                  type="select"
                  :label="$t('Modelo')"
                  :options="getComboModelo"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="serialNumber"
                  v-model="filters.serialNumber"
                  type="number"
                  :label="$t('Número de série')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="initialNumber"
                  v-model="filters.initialNumber"
                  type="number"
                  :label="$t('Faixa inicial')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="finalNumber"
                  v-model="filters.finalNumber"
                  type="number"
                  :label="$t('Faixa final')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="issueDate"
                  v-model="filters.issueDate"
                  type="date-range-picker"
                  :label="$t('Período de emissão')"
                />
              </b-col>
            </b-row>
          </div>
        </e-filters>

        <b-card-actions
          action-refresh
          :show-loading="fetching"
          @refresh="filterData"
        >
          <div slot="title">
            <e-page-size-selector
              :per-page="paging.pageSize"
              @change="pageSizeChange"
            />
          </div>

          <b-table
            ref="invalidates-table"
            show-empty
            responsive
            striped
            class="bordered"
            :empty-text="getEmptyTableMessage($tc('INVOICE.NAME'), 'female')"
            :fields="fields"
            :items="invalidates"
            no-local-sorting
            :sort-by.sync="sorting.sortBy"
            :sort-desc.sync="sorting.sortDesc"
            @sort-changed="getData"
          >
            <template #cell(action)="row">
              <e-grid-actions
                :show-update="false"
                :show-delete="false"
                :buttons="gridButtons()"
                @download-xml="onDownloadXml(row)"
              />
            </template>
          </b-table>

          <b-row class="pt-1">
            <b-col cols="6">
              <e-pagination-summary
                :current-page="paging.currentPage"
                :per-page="paging.pageSize"
                :total="paging.rowCount"
                :total-on-page="paging.rowsInCurrentPage"
              />
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="paging.currentPage"
                align="right"
                :total-rows="paging.rowCount"
                :per-page="paging.pageSize"
                aria-controls="invalidates-table"
                @change="pageChange"
              />
            </b-col>
          </b-row>
        </b-card-actions>

        <invalidate-create-sidebar
          ref="invalidate_sidebar"
          @save="getData"
        />

        <fab
          :main-tooltip="$t('Inutilizar Faixa')"
          @click="onCreateInvalidate"
        />

        <!-- <consult-invoice-modal ref="consultInvoiceModal" /> -->
      </section>
    </b-tab>
    <b-tab :title="$t('Quebras de Sequência')">
      <section>
        <invalidate-gap-form
          id="invalidate_gap"
          ref="invalidateGapForm"
          name="invalidate_gap"
        />
      </section>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BRow, BCol, BPagination, BTable, BTabs, BTab } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EStoreCombo } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { invoiceDomains, statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import InvalidateCreateSidebar from './components/InvalidateCreateSidebar.vue'
import InvalidateGapForm from './components/InvalidateGapForm.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPageSizeSelector,
    EPaginationSummary,
    fab,
    EFilters,
    EGridActions,
    EStoreCombo,
    InvalidateCreateSidebar,
    InvalidateGapForm,
  },

  mixins: [statusTypes, invoiceDomains],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/invalidate', ['invalidates', 'paging', 'sorting', 'filters']),

    getComboModelo() {
      return [
        { value: 'NFE', label: this.invoiceModelLabel.NFE },
        {
          value: 'NFCE',
          label: this.invoiceModelLabel.NFCE,
        },
      ]
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          tdClass: 'text-left',
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
        },
        {
          label: this.$t('Número de Série'),
          key: 'serialNumber',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Faixa Inicial'),
          key: 'initialNumber',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Faixa Final'),
          key: 'finalNumber',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Data Emissão'),
          key: 'createdDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.date(value),
          sortable: true,
        },
        {
          label: this.$t('Justificativa'),
          key: 'justification',
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/invoice/invalidate', [
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'fetchInvoicesInvalidates',
      'resetFilters',
    ]),
    ...mapActions('pages/invoice/invoice/invoiceMaintain', {
      stInvoiceDownload: 'invoiceDownload',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchInvoicesInvalidates()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async onCreateInvalidate() {
      this.$refs.invalidate_sidebar.show()
    },

    async onDownloadXml(row) {
      try {
        this.fetching = true

        const ret = await this.stInvoiceDownload({
          pathUrl: row.item?.xmlPath,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          const linkSource = `data:application/pdf;base64,${ret.responsePayload}`
          const downloadLink = document.createElement('a')
          const fileName = `Inutilizacao_Loja_${row.item.storeId}_Inicial_${row.item.initialNumber}_Final_${row.item.finalNumber}.xml`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao baixar o xml!')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    gridButtons() {
      const btns = [
        {
          icon: 'download',
          variant: 'primary',
          title: this.$t('Download do XML'),
          event: 'download-xml',
          hide: false,
        },
      ]

      return btns
    },
  },
}
</script>

<style></style>
