<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="8">
            <e-store-combo
              v-model="filters.storeIds"
              :required="true"
              :multiple="true"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="ncm"
              v-model="filters.model"
              type="select"
              :label="$t('Modelo')"
              :options="getComboModelo"
              required
              class="required"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="startDate"
              v-model="filters.startDate"
              type="datepicker"
              :max="today"
              :label="$t('Data de início')"
              required
              class="required"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="gaps-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('INVOICE.NAME'), 'female')"
        :fields="fields"
        :items="gaps"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="gridButtons()"
            @invalidate-sequence="onCreateInvalidate(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="invalidates-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <invalidate-create-sidebar ref="invalidate_sidebar" />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EStoreCombo } from '@/views/components'
import { invoiceDomains, statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import InvalidateCreateSidebar from './InvalidateCreateSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
    EGridActions,
    EStoreCombo,
    InvalidateCreateSidebar,
    // ConsultInvoiceModal,
  },

  mixins: [statusTypes, invoiceDomains],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/invalidate/invalidateGap', ['gaps', 'paging', 'sorting', 'filters']),
    today() {
      return new Date()
    },
    getComboModelo() {
      return [
        { value: 'NFE', label: this.invoiceModelLabel.NFE },
        {
          value: 'NFCE',
          label: this.invoiceModelLabel.NFCE,
        },
      ]
    },

    fields() {
      return [
        {
          label: this.$t('Loja'),
          key: 'storeId',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
          formatter: (val, index, item) =>
            this.$options.filters.storeName({ id: item.storeId, name: item.storeName }),
          sortable: true,
        },
        {
          label: this.$t('Número de Série'),
          key: 'serialNumber',
          tdClass: 'text-right',
          thStyle: { width: '60px' },
          sortable: true,
        },
        {
          label: this.$t('Modelo'),
          key: 'model',
          tdClass: 'text-right',
          thStyle: { width: '60px' },
          sortable: true,
        },
        {
          label: this.$t('Faixa Inicial Sugerida'),
          key: 'suggestedStartingTrack',
          tdClass: 'text-right',
          thStyle: { width: '60px' },
          sortable: true,
        },
        {
          label: this.$t('Faixa Final Sugerida'),
          key: 'suggestedEndingTrack',
          tdClass: 'text-right',
          thStyle: { width: '60px' },
          sortable: true,
        },
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
      ]
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/invoice/invalidate/invalidateGap', [
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'fetchInvoicesInvalidatesGaps',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchInvoicesInvalidatesGaps()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async onCreateInvalidate(row) {
      const { model, serialNumber, storeId, suggestedEndingTrack, suggestedStartingTrack } =
        row.item

      this.$refs.invalidate_sidebar.showPreFilled({
        model,
        serialNumber,
        storeId,
        initialNumber: suggestedStartingTrack,
        finalNumber: suggestedEndingTrack,
        justification: null,
      })
    },

    gridButtons() {
      const btns = [
        {
          icon: 'x-circle',
          variant: 'danger',
          title: this.$t('Inutilizar Faixa'),
          event: 'invalidate-sequence',
          hide: false,
        },
      ]

      return btns
    },
  },
}
</script>

<style></style>
