var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tabs",
    { attrs: { fill: "" } },
    [
      _c("b-tab", { attrs: { title: _vm.$t("Sequências Inutilizadas") } }, [
        _c(
          "section",
          [
            _c(
              "e-filters",
              {
                attrs: { searching: _vm.fetching },
                on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("e-store-combo", {
                              attrs: { required: false },
                              model: {
                                value: _vm.filters.storeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "storeId", $$v)
                                },
                                expression: "filters.storeId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "ncm",
                                type: "select",
                                label: _vm.$t("Modelo"),
                                options: _vm.getComboModelo,
                              },
                              model: {
                                value: _vm.filters.model,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "model", $$v)
                                },
                                expression: "filters.model",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "serialNumber",
                                type: "number",
                                label: _vm.$t("Número de série"),
                              },
                              model: {
                                value: _vm.filters.serialNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "serialNumber", $$v)
                                },
                                expression: "filters.serialNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "initialNumber",
                                type: "number",
                                label: _vm.$t("Faixa inicial"),
                              },
                              model: {
                                value: _vm.filters.initialNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "initialNumber", $$v)
                                },
                                expression: "filters.initialNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "finalNumber",
                                type: "number",
                                label: _vm.$t("Faixa final"),
                              },
                              model: {
                                value: _vm.filters.finalNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "finalNumber", $$v)
                                },
                                expression: "filters.finalNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "issueDate",
                                type: "date-range-picker",
                                label: _vm.$t("Período de emissão"),
                              },
                              model: {
                                value: _vm.filters.issueDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "issueDate", $$v)
                                },
                                expression: "filters.issueDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-card-actions",
              {
                attrs: { "action-refresh": "", "show-loading": _vm.fetching },
                on: { refresh: _vm.filterData },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "title" }, slot: "title" },
                  [
                    _c("e-page-size-selector", {
                      attrs: { "per-page": _vm.paging.pageSize },
                      on: { change: _vm.pageSizeChange },
                    }),
                  ],
                  1
                ),
                _c("b-table", {
                  ref: "invalidates-table",
                  staticClass: "bordered",
                  attrs: {
                    "show-empty": "",
                    responsive: "",
                    striped: "",
                    "empty-text": _vm.getEmptyTableMessage(
                      _vm.$tc("INVOICE.NAME"),
                      "female"
                    ),
                    fields: _vm.fields,
                    items: _vm.invalidates,
                    "no-local-sorting": "",
                    "sort-by": _vm.sorting.sortBy,
                    "sort-desc": _vm.sorting.sortDesc,
                  },
                  on: {
                    "update:sortBy": function ($event) {
                      return _vm.$set(_vm.sorting, "sortBy", $event)
                    },
                    "update:sort-by": function ($event) {
                      return _vm.$set(_vm.sorting, "sortBy", $event)
                    },
                    "update:sortDesc": function ($event) {
                      return _vm.$set(_vm.sorting, "sortDesc", $event)
                    },
                    "update:sort-desc": function ($event) {
                      return _vm.$set(_vm.sorting, "sortDesc", $event)
                    },
                    "sort-changed": _vm.getData,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(action)",
                      fn: function (row) {
                        return [
                          _c("e-grid-actions", {
                            attrs: {
                              "show-update": false,
                              "show-delete": false,
                              buttons: _vm.gridButtons(),
                            },
                            on: {
                              "download-xml": function ($event) {
                                return _vm.onDownloadXml(row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "b-row",
                  { staticClass: "pt-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("e-pagination-summary", {
                          attrs: {
                            "current-page": _vm.paging.currentPage,
                            "per-page": _vm.paging.pageSize,
                            total: _vm.paging.rowCount,
                            "total-on-page": _vm.paging.rowsInCurrentPage,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("b-pagination", {
                          attrs: {
                            align: "right",
                            "total-rows": _vm.paging.rowCount,
                            "per-page": _vm.paging.pageSize,
                            "aria-controls": "invalidates-table",
                          },
                          on: { change: _vm.pageChange },
                          model: {
                            value: _vm.paging.currentPage,
                            callback: function ($$v) {
                              _vm.$set(_vm.paging, "currentPage", $$v)
                            },
                            expression: "paging.currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("invalidate-create-sidebar", {
              ref: "invalidate_sidebar",
              on: { save: _vm.getData },
            }),
            _c("fab", {
              attrs: { "main-tooltip": _vm.$t("Inutilizar Faixa") },
              on: { click: _vm.onCreateInvalidate },
            }),
          ],
          1
        ),
      ]),
      _c("b-tab", { attrs: { title: _vm.$t("Quebras de Sequência") } }, [
        _c(
          "section",
          [
            _c("invalidate-gap-form", {
              ref: "invalidateGapForm",
              attrs: { id: "invalidate_gap", name: "invalidate_gap" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }