<template>
  <div>
    <e-sidebar
      :title="$t('Inutilizar faixa documento fiscal')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveInvalidate"
      @hidden="cancel"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="form"
            name="form"
          >
            <b-row>
              <b-col md="12">
                <e-store-combo
                  id="storeId"
                  v-model="invalidate.storeId"
                  name="storeId"
                  required
                  :clearable="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="auto">
                <FormulateInput
                  id="model"
                  v-model="invalidate.model"
                  type="select"
                  :label="$t('Modelo')"
                  :options="getComboModelo"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="auto">
                <FormulateInput
                  id="serialNumber"
                  v-model="invalidate.serialNumber"
                  type="number"
                  :label="$t('Número de Série')"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="auto">
                <FormulateInput
                  id="initialNumber"
                  v-model="invalidate.initialNumber"
                  type="number"
                  :label="$t('Faixa Inicial')"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="auto">
                <FormulateInput
                  id="finalNumber"
                  v-model="invalidate.finalNumber"
                  type="number"
                  :label="$t('Faixa Final')"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <FormulateInput
                  id="justification"
                  v-model="invalidate.justification"
                  type="textarea"
                  :label="$t('Justificativa')"
                  class="required"
                  validation="^required|min:15,length"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import { invoiceDomains, statusTypes } from '@/mixins'

export default {
  components: {
    BRow,
    BCol,
    ESidebar,
    EStoreCombo,
  },

  mixins: [statusTypes, invoiceDomains],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/invalidate/invoiceInvalidateMaintain', {
      invalidate: 'invalidate',
    }),

    getComboModelo() {
      return [
        { value: 'NFE', label: this.invoiceModelLabel.NFE },
        {
          value: 'NFCE',
          label: this.invoiceModelLabel.NFCE,
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/invoice/invalidate/invoiceInvalidateMaintain', {
      invalidateInvoice: 'invalidateInvoice',
      cleanState: 'cleanState',
    }),
    ...mapMutations('pages/invoice/invalidate/invoiceInvalidateMaintain', {
      setInvoiceInvalidate: 'SET_INVOICE_INVALIDATE',
    }),

    async showPreFilled(invalidate) {
      this.setInvoiceInvalidate({
        ...invalidate,
        storeId: invalidate.storeId.toString(),
      })
      this.showCreate()
    },
    async show() {
      this.showCreate()
    },

    showCreate() {
      this.edit = false
      this.showSidebar = true
    },

    async saveInvalidate() {
      this.$refs.form.showErrors()
      if (this.$refs.form.isValid) {
        const confirmed = await this.confirm({
          title: this.$t('Confirma a inutilização da faixa informada?'),
          text: this.$t(''),
        })
        if (!confirmed) return

        try {
          this.saving = true
          await this.invalidateInvoice()
          this.showSuccess({
            message: this.$t(`Faixa invalidada com sucesso`),
          })
          this.showSidebar = false
          this.cleanState()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    cancel() {
      this.$formulate.reset('form')
      this.cleanState()
      this.showSidebar = false
    },
  },
}
</script>

<style></style>
